$(function() {
	// -------------------------------------------
	// ヘッダー関連処理(PC/SMP共通) ※2021/11現在、以下で使用
	//   ・PC/SMP賃貸配下(Spring)
	//   ・PC/SMPマイページ配下(Spring)
	//   ・PC/SMP不動産トップ(CMS)
	//   ・PC/SMP売買配下(CMS)
	// -------------------------------------------
	window.Nifty = window.Nifty || {};
	window.Nifty.Common = window.Nifty.Common || {};

	// ※ヘッダーのお気に入り数表示のみの為、Nifty.Rent.Storage、Nifty.Buy.Storageは使用せず個別に実装する(JSファイルの依存関係を避ける為)
	Nifty.Common.Header = {
		Keys: {
			STORAGE_RENT: 'myhome.nifty.com:rent'
			,STORAGE_BUY: 'myhome.nifty.com:buy'
			,BUKKEN_FAVORITE: 'bukken-favorite'
		}

		,$bukkenFavoriteCount: $('[data-bukken-favorite-count]')           // 件数を設定する箇所
		,$bukkenFavoriteCountWrap: $('[data-bukken-favorite-count-wrap]')  // お気に入りの有無で表示/非表示を切り替える箇所

		,getItemsCount: function(storageKey, itemsKey) {
			try {
				var json = localStorage.getItem(storageKey);
				if (json) {
					var data = (json) ? JSON.parse(json) : {};
					if (data && data[itemsKey]) {
						return data[itemsKey].length;
					}
				}
			} catch (e) {}
			return 0;
		}
		// お気に入り件数更新(賃貸/売買の合計値)
		,updateHeaderCount: function() {
			var bukkenFavoriteCount = this.getItemsCount(this.Keys.STORAGE_RENT, this.Keys.BUKKEN_FAVORITE) + this.getItemsCount(this.Keys.STORAGE_BUY, this.Keys.BUKKEN_FAVORITE);
			this.$bukkenFavoriteCount.html(bukkenFavoriteCount);
			if (bukkenFavoriteCount > 0) {
				this.$bukkenFavoriteCountWrap.removeClass('is-hidden').show();
			} else {
				this.$bukkenFavoriteCountWrap.addClass('is-hidden').hide();
			}
		}
	};

	// SMPの検索結果画面の上部固定ボタンリスト(条件を保存/ABテスト:プッシュ通知/新着通知/)
	if($('#fixed-btn-set').length) {
		// 本来の固定ボタンリストのオフセットtop(上部に固定する前)
		const originalBtnSetOffsetTop = $('#fixed-btn-set').offset().top;
		// 張り付きを開始するスクロール閾値(貼り付け後の高さを考慮してその分は減らす)
		const stickingThreshold = originalBtnSetOffsetTop - 50;
		$(window).on('scroll load resize', () => {
			let top = $(window).scrollTop();
			if (stickingThreshold < top) {
				$('#fixed-btn-set').addClass('is-fixed-top-50');
			} else {
				$('#fixed-btn-set').removeClass('is-fixed-top-50');
			}
		});
	}
});
